import { useState, useEffect } from 'react';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Accomodations from "./pages/Accomodations";
import Schedule from "./pages/Schedule";
import Attractions from "./pages/Attractions";
import Story from "./pages/Story";
import FAQ from "./pages/FAQ";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Overlay from './components/Overlay'; // Create Overlay component for password input
import Sabi from './pages/Sabi'; // Import Sabi component

const theme = createTheme({
  typography: {
    fontFamily: ['Poiret One', 'Georgia', 'serif'].join(','),
  },
  palette: {
    primary: {
      main: '#808080', // Set primary color to grey
    },
    text: {
      primary: '#000000', // Set text color to black
    },
    olive: {
      main: '#6A6432',
      light: '#87835b',
      dark: '#4a4623',
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '&:hover': {
            opacity:0.5,
          },
        },
      },
    },
  },
});

export default function App() {
  const [showPasswordOverlay, setShowPasswordOverlay] = useState(false); // Set default password state to false

  useEffect(() => {
    const passwordEntered = localStorage.getItem('passwordEntered'); // Check if password was already entered
    if (!passwordEntered) {
      setShowPasswordOverlay(true); // If password was not entered, show overlay
    }
  }, []);

  const handlePasswordSubmit = (password) => {
    if (password === 'celebrate8324') { // Change 'yourpassword' to your actual password
      localStorage.setItem('passwordEntered', 'true'); // Save password entered flag to local storage
      setShowPasswordOverlay(false); // Hide password overlay
    } else {
      alert('Incorrect password. Please try again.'); // Show alert for incorrect password
    }
  };

  return (
    <ThemeProvider theme={theme}>  
      <div> 
        {showPasswordOverlay && <Overlay onSubmit={handlePasswordSubmit} />} {/* Show overlay if needed */}
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="story" element={<Story />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="stay" element={<Accomodations />} />
            <Route path="sights" element={<Attractions />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="munchkins" element={<Sabi />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

function Layout() {
  return (
    <div>
      <Navbar />
      <Outlet />
    </div>
  );
}
