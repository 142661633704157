import React from 'react';
import { Typography, Link, Box, Container, IconButton } from '@mui/material';
import { Room, Phone, Public } from '@mui/icons-material'; // Import necessary icons

const linkStyle = {
  textDecoration: 'underline',
  textDecorationThickness: "0.075rem",
  color: 'inherit',
};

const HotelInfo = ({ imageUrl, fontColor, title, websiteLink, mapLink, phone }) => {
  const outerStyles = {
    width: '100%',
    maxWidth: '500px',
    minHeight: '350px',
    maxHeight: '500px',
    padding: '0px 75px',
  };

  const innerStyles = {
    color: `${fontColor}`,
    textAlign: "center",
    padding: '0px 85px',
  };

  return (
    <Box
      display={'flex'}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        backgroundImage: `url('${imageUrl}')`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box sx={outerStyles} display={'flex'} flexDirection="column" justifyContent="center" alignItems="center">
        <Box sx={innerStyles} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant='body1' textAlign={'center'} letterSpacing={".2rem"} textTransform={"uppercase"}>
            {title}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', paddingTop: '20px' }}>
            <IconButton onClick={() => window.open(websiteLink)}>
              <Public sx={{ color: '#EEEEEE' }} />
            </IconButton>
            <IconButton onClick={() => window.open(mapLink)}>
              <Room sx={{ color: '#EEEEEE' }}/>
            </IconButton>
            <IconButton onClick={() => { if (phone) window.open(`tel:${phone}`) }}>
              <Phone sx={{ color: '#EEEEEE' }}/>
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Accomodations = () => {
  let titles = ["Hotel Northampton", "Fairfield Inn Suites Marriott Northampton", "Quality Inn & Suites Northampton-Amherst"]
  let websiteLinks = [
    'https://www.hotelnorthampton.com/',
    'https://www.marriott.com/events/start.mi?id=1693927881136&key=GRP',
    'https://www.choicehotels.com/reservations/groups/jp31y4?checkInDate=2024-08-02&checkOutDate=2024-08-04&ratePlanCode=BUUYUL'
  ];
  let mapLinks = [
    'https://maps.app.goo.gl/jozfZDRtyuTSA8WQA',
    'https://maps.app.goo.gl/jwWs5tja78f1fxxaA',
    'https://maps.app.goo.gl/9UcjeZjodnMncEYm9'
  ];
  let phoneNumbers = [
    '(413) 584-3100',
    '(413) 587-9800',
    '(413) 341-1461'
  ];

  return (
    <Container maxWidth="sm">
      <Box display={'flex'} flexDirection={'column'} justifyContent={"center"} alignItems={"center"} sx={{ textAlign: "center" }}>
        <Typography variant='h3' textAlign={'center'} letterSpacing={".2rem"} textTransform={"uppercase"} pb={1}>Stay</Typography>
        <Box p={1} mx={1}>
          <Typography variant='h5'>
            We have reserved room blocks at 3 hotels in Northampton. To get the discounted rate you must book by 6/2/24. When calling ask for the McNichols-McCadden wedding room block. Alternatively, there are many local airbnbs and charming B&Bs nearby.
          </Typography>
        </Box>
        {[0, 1, 2].map(index => (
          <HotelInfo
            key={index}
            fontColor={"#FFFFFF"}
            imageUrl={`/blob_${index + 1}_big.png`}
            title={titles[index]}
            websiteLink={websiteLinks[index]}
            mapLink={mapLinks[index]}
            phone={phoneNumbers[index]}
          />
        ))}
      </Box>
    </Container>
  );
};

export default Accomodations;
export { HotelInfo };
