import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import SplitSection from '../components/SplitSection';

const Attractions = () => {
    return (
        <Container maxWidth="xl">
            <Typography variant='h2' textAlign={'center'} letterSpacing={".2rem"} textTransform={"uppercase"} pb={2}>Sights</Typography>
            <Typography variant='h3' textAlign={'center'} pb={2}>The Pioneer Valley</Typography>
            <Box px={1} mx={1}>
            <Typography variant='h6' pb={2} textAlign={'center'}>We moved to the Pioneer Valley in 2022.
             The area is known for the 5 College Consortium which comprises Smith College, Mount Holyoke College, Amherst College, Hampshire College, and UMass Amherst.
              It is the asparagus capital of the world and the site of the first documented accusation of witchcraft (nearly 50 years before the Salem witch trials).</Typography>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', maxHeight: '600px', overflow: 'hidden' }}>
                    {/* <img src="/pioneer_map.png" alt="Attractions" style={{ width: '100%', objectFit: 'cover' }} /> */}
                    <iframe src="https://www.google.com/maps/d/embed?mid=19IWb7q9ssc-PSY87pgzbJume6P8s3A4&ehbc=2E312F" style={{minHeight:400, maxHeight:600, width:"100%"}}></iframe>
                </div>
            </Box>
            <Box px={1} mx={1} pt={2}>
                <Typography variant='h6' textAlign={'center'}>We recommend you stay in <b>Northampton</b>, the most happening part of the Pioneer Valley. There are many great food/drink options and things to do in Northampton. In particular we recommend:</Typography>
                <SplitSection imgPath="/downtown.jpeg" bgColor="white" reverse={true}>
                    <Box sx={{display:"flex", textAlign:"left", flexDirection:"column", alignItem:"left", justifyContent:"center"}}>
                        <Typography variant='h4' pt={1}>Food & Drink</Typography>
                        <Typography variant='body'>Woodstar Cafe</Typography>
                        <Typography variant='body2'>Variety of local, fresh breakfast and lunch options</Typography>
                        <Typography variant="body">Paul and Elizabeth’s</Typography>
                        <Typography variant="body2">Macrobiotic pescatarian fare</Typography>
                        <Typography variant="body">Pita Pockets</Typography>
                        <Typography variant="body2">Quick, cheap, delicious. Two locations - Amherst and Northampton. Very nice people working there</Typography>
                        <Typography variant="body">Herrel’s </Typography>
                        <Typography variant="body2">creative ice cream flavors</Typography>
                        <Typography variant="body">Tunnel Bar</Typography>
                        <Typography variant="body2">elaborate cocktails served in an old train station terminal</Typography>
                        <Typography variant="body">Ye Ole Watering Hole</Typography>
                        <Typography variant="body2">pool tables, sports, beer can collection and fresh squeezed grapefruit juice </Typography>

                        <Typography variant='h4' pt={1}>Sites & Activities</Typography>
                        <Typography variant="body">Smith College Museum of Art  </Typography>
                        <Typography variant="body2">Free! Well curated exhibits including works by Georgia O’Keefe and Picasso </Typography>
                        <Typography variant="body">Smith College Botanic Garden </Typography>
                        <Typography variant="body2">Free (donation suggested) beautiful greenhouses on Smith College campus </Typography>
                        <Typography variant="body">Norwottuck bike path </Typography>
                        <Typography variant="body2">beautiful path, mostly shaded. Runs from Easthampton to Amherst. Good for walking, jogging,  biking, skating </Typography>
                    </Box>
                </SplitSection>
                
            </Box>
            <Box px={1} mx={1}>
                <Typography variant='h6' textAlign={'center'}>Nearby towns of <b>Hadley</b>, <b>Amherst</b>, and <b>South Deerfield</b> are very charming as well and easily accessible by car. Recommendations for those towns include:</Typography>
                <SplitSection imgPath="/cow.jpeg" bgColor="white" reverse={false}>
                    <Box sx={{display:"flex", textAlign:"left", flexDirection:"column", alignItem:"left", justifyContent:"center"}}>
                    <Typography variant='h4' pt={1}>Food & Drink</Typography>
                    <Typography variant='body'>Esselon Cafe</Typography>
                    <Typography variant='body2'>Lots of indoor and outdoor seating options</Typography>
                    <Typography variant="body">Leo’s Table</Typography>
                    <Typography variant="body2">yummy breakfast menu</Typography>
                    <Typography variant="body">Flayvors of Cook Farm</Typography>
                    <Typography variant="body2">dairy farm selling ice cream with prime views of the dairy cows</Typography>
                    <Typography variant="body">Carrs Cidery</Typography>
                    <Typography variant="body2">cute outdoor cidery with baby goats</Typography>
                    <Typography variant="body">Treehouse Brewery</Typography>
                    <Typography variant="body2">huge brewery with a variety of draft beers and New Haven style pizza</Typography>
                    <Typography variant='h4' pt={1}>Sites & Activities</Typography>
                    <Typography variant="body">Mount Holyoke Range State Park</Typography>
                    <Typography variant="body2">8+ miles of hiking trails. You can reach the summit of Mount Holyoke by car or foot</Typography>
                    <Typography variant="body">Amherst Saturday Farmers Market</Typography>
                    <Typography variant="body2">huge summer market with a variety of fresh produce, pastries and crafts</Typography>
                    <Typography variant="body">Emily Dickinson House</Typography>
                    <Typography variant="body2">guided tours and exhibits in Emily Dickinson’s home</Typography>
                    <Typography variant="body">Eric Carle Museum</Typography>
                    <Typography variant="body2">must see for lovers of The Very Hungry Caterpillar</Typography>
                    <Typography variant="body">Yankee Candle</Typography>
                    <Typography variant="body2">Flagship store. Definitely a treat. WAY more than candles in store</Typography>
                    <Typography variant="body">Mount Sugarloaf</Typography>
                    <Typography variant="body2">one of the best views, ~3 mile hike with pavilion and picnic tables at the top</Typography>
                    </Box>
                </SplitSection>                
            </Box>
        </Container>
    );
};

export default Attractions;
