// SabiPage.js
import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import FriendPhotoCarousel from '../components/FriendPhotoCarousel';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import SpecialPasswordOverlay from '../components/SpecialPasswordOverlay'; // Import SpecialPasswordOverlay component
import SplitSection from '../components/SplitSection';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconButton } from '@mui/material';
import Room from '@mui/icons-material/Room';


const MapIconButton = ({ mapLink }) => { // Destructure props here
    return (
        <IconButton onClick={() => window.open(mapLink)}>
            <Room sx={{ color: '#111111' }}/>
        </IconButton>
    );
}

const SabiPage = () => {
    const [showSpecialPasswordOverlay, setShowSpecialPasswordOverlay] = useState(false);

    useEffect(() => {
        const specialPasswordEntered = localStorage.getItem('specialPasswordEntered');
        setShowSpecialPasswordOverlay(!specialPasswordEntered);
        // window.location.reload(); // Reload the page
    }, []);

    const handleSpecialPasswordSubmit = (password) => {
        if (password === 'munchkin$') { // Change 'specialpassword' to your actual special password
            localStorage.setItem('specialPasswordEntered', 'true');
            setShowSpecialPasswordOverlay(false);
            window.location.reload(); // Reload the page
        } else {
            alert('Incorrect special password. Please try again.');
        }
    }
    const theme = useTheme();
    const photos = [
        {
          url: '/friends/IMG_0090_crop.jpg',
          bgUrl: 'blob_1_big.png',
          alt: 'Photo 1',
          title: "Year 1",
          caption: 'Hunter and Maddy met at a party at USC the first weekend of senior year. They spent their time at tailgates, college bars in LA, and occasionally studying before their graduation in May.',
        },
        {
            url: '/friends/IMG_0656_crop.jpg',
            bgUrl: 'blob_4_big.png',
            alt: 'Photo 4',
            title: "Year 4",
            caption: 'Maddy and Hunter quit their jobs in San Francisco, traveled around the world, and moved across the country. Maddy moved to New Haven for grad school, and Hunter followed to New York.',
        },
        {
            url: '/friends/IMG_3025_crop.jpg',
            bgUrl: 'blob_5_big.png',
            alt: 'Photo 5',
            title: "Year 5",
            caption: 'And just as they were settling in, the Covid-19 pandemic disrupted in-person learning and life in New York. Hunter moved in with Maddy (and her roommate Annie) in New Haven, then the couple spent some time with Hunter’s parents in Florida. ',
        },
        {
            url: '/friends/IMG_4393_crop.jpg',
            bgUrl: 'blob_6_big.png',
            alt: 'Photo 6',
            title: "Year 6",
            caption: 'Maddy and Hunter moved into their own apartment in New Haven and adopted their precious angel babies Sokka and Katara. Hunter discovered his love and aptitude for teaching, while Maddy worked as a nurse swabbing Yalies for covid and administering the first covid vaccines.',
        },
        {
            url: '/friends/IMG_4589_crop.jpg',
            bgUrl: 'blob_7_big.png',
            alt: 'Photo 7',
            title: "Year 7",
            caption: 'Maddy graduated nursing school, Hunter got into grad school for his PhD, Hunter proposed to Maddy, and the couple bought their first home in Amherst, MA',
        },
        {
            url: '/friends/IMG_7916_crop.jpg',
            bgUrl: 'blob_8_big.png',
            alt: 'Photo 8',
            title: "Year 8",
            caption: 'Maddy settled into her new job as a Family Nurse Practitioner and Hunter started learning how large language models work.',
        },
        {
            url: '/friends/IMG_8304_crop.jpg',
            bgUrl: 'blob_8_big.png',
            alt: 'Photo 8',
            title: "Year 8",
            caption: 'Maddy settled into her new job as a Family Nurse Practitioner and Hunter started learning how large language models work.',
        },
        {
            url: '/friends/IMG_0191_crop.jpg',
            bgUrl: 'blob_8_big.png',
            alt: 'Photo 8',
            title: "Year 8",
            caption: 'Maddy settled into her new job as a Family Nurse Practitioner and Hunter started learning how large language models work.',
        },
        {
            url: '/friends/IMG_2965_crop.jpg',
            bgUrl: 'blob_8_big.png',
            alt: 'Photo 8',
            title: "Year 8",
            caption: 'Maddy settled into her new job as a Family Nurse Practitioner and Hunter started learning how large language models work.',
        },
        {
            url: '/friends/IMG_7621_crop.jpg',
            bgUrl: 'blob_8_big.png',
            alt: 'Photo 8',
            title: "Year 8",
            caption: 'Maddy settled into her new job as a Family Nurse Practitioner and Hunter started learning how large language models work.',
        },
        {
            url: '/friends/IMG_3622_crop.jpg',
            bgUrl: 'blob_8_big.png',
            alt: 'Photo 8',
            title: "Year 8",
            caption: 'Maddy settled into her new job as a Family Nurse Practitioner and Hunter started learning how large language models work.',
        },
        {
            url: '/friends/IMG_3624_crop.jpg',
            bgUrl: 'blob_8_big.png',
            alt: 'Photo 8',
            title: "Year 8",
            caption: 'Maddy settled into her new job as a Family Nurse Practitioner and Hunter started learning how large language models work.',
        },
        {
            url: '/friends/IMG_3863_crop.jpg',
            bgUrl: 'blob_8_big.png',
            alt: 'Photo 8',
            title: "Year 8",
            caption: 'Maddy settled into her new job as a Family Nurse Practitioner and Hunter started learning how large language models work.',
        },
        {
            url: '/friends/IMG_1801_crop.jpg',
            bgUrl: 'blob_8_big.png',
            alt: 'Photo 8',
            title: "Year 8",
            caption: 'Maddy settled into her new job as a Family Nurse Practitioner and Hunter started learning how large language models work.',
        },
        {
            url: '/friends/IMG_0398_crop.jpg',
            bgUrl: 'blob_8_big.png',
            alt: 'Photo 8',
            title: "Year 8",
            caption: 'Maddy settled into her new job as a Family Nurse Practitioner and Hunter started learning how large language models work.',
        },
        {
            url: '/friends/IMG_2052_crop.jpg',
            bgUrl: 'blob_8_big.png',
            alt: 'Photo 8',
            title: "Year 8",
            caption: 'Maddy settled into her new job as a Family Nurse Practitioner and Hunter started learning how large language models work.',
        },
    ]

    const isMedScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let waxwingLink = "https://maps.app.goo.gl/Wjn8KN8j7PHyx5jV6";

  return (
    <div>
    {showSpecialPasswordOverlay && <SpecialPasswordOverlay onSubmit={handleSpecialPasswordSubmit} />}
        <Container maxWidth="xl">
                <Typography variant='h2' textAlign={'center'} letterSpacing={".2rem"} textTransform={"uppercase"} pb={2} color={"#BF8234"} sx={{fontSize: isSmallScreen ? '3rem' : '6rem'}}>Munter's Munchkins</Typography>
                <Typography variant='h4' pb={2} textAlign={'center'}>You all are so important to us and we can’t wait to celebrate this weekend with you!</Typography>
                <Typography variant='h3' textAlign={'center'} letterSpacing={".2rem"} textTransform={"uppercase"} pb={2} pt={1}>The Crew</Typography>
                <Typography variant='h5' pb={2} textAlign={'center'}>Adrienne Visani, Ali McCadden, Annie Hall, Collin McCadden, David Butler, Elizabeth Flood, Kamni Kalpathi, Kelsey Moore, Luke Iknadosian, Marie Kilaru, Owen McCadden, Raimy Shin, Surafael Yared, Tom Osmers, Travis Fox</Typography>
                <Box sx={{ display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Box 
                        sx={{ [theme.breakpoints.down('sm')]: {
                                        width: '400px',
                                    },
                                    [theme.breakpoints.up('sm')]: {
                                        width: '750px',
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        width: '750px',
                                    },
                                    [theme.breakpoints.up('xl')]: {
                                        width: '750px',
                                    },
                                }}
                                >
                            <FriendPhotoCarousel photos={photos} />
                        </Box>
                    </Box>
                    <Typography variant='h3' textAlign={'center'} letterSpacing={".2rem"} textTransform={"uppercase"} pb={2}>The Details</Typography>
                    <SplitSection imgPath="/mrc_camp_edit.jpg" bgColor="white" reverse={true}>
                            <Typography variant='h6'>Our venue has accommodations for the wedding party (and your partners) to stay onsite Friday and Saturday evenings. Think summer camp sleepover vibes. We will share more details in the coming months about how to book the accommodations.<br/></Typography>
                    </SplitSection>
                    <SplitSection imgPath="/bar_stools.jpeg" bgColor="white" reverse={isSmallScreen}>
                            <Typography variant='h6'>In addition to the main events of the weekend, we would love for you and your partners to join us for a dinner Friday evening prior to the welcome event, around 5pm at the Waxwing Cafe. <MapIconButton mapLink={waxwingLink} /><br/></Typography>
                    </SplitSection>
                    <SplitSection imgPath="/boat.jpeg" bgColor="white" reverse={true}>
                            <Typography variant='h6'>We will be having a combined Bach Bash May 31st - June 2nd in Austin, TX with more information coming soon. <br/></Typography>
                    </SplitSection>
                    <SplitSection imgPath="/color_pal_crop.jpg" bgColor="white" reverse={isSmallScreen}>
                            <Typography variant='h6'>Our color palette for the wedding is warm earthy shades of olive, mustard, and terracotta. Details about wedding party attire to follow. <br/></Typography>
                    </SplitSection>
                    
        </Container>
    </div>
  );
};

export default SabiPage;
