// SpecialPasswordOverlay.js
import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';

const SpecialPasswordOverlay = ({ onSubmit }) => {
  const [password, setPassword] = useState('');

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(password);
  };

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      backgroundColor="#BF8234"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex={9999}
    >
      <Box
        backgroundColor="#fff"
        padding={3}
        borderRadius={5}
        maxWidth={400}
        width="80%"
        textAlign="center"
      >
        <Typography variant="h5" gutterBottom>
          Enter Special Password
        </Typography>
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button variant="outlined" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default SpecialPasswordOverlay;
