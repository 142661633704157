import React, { useState, useRef } from 'react';
import Carousel from './my_react-material-ui-carousel/Carousel';
import { Typography } from '@mui/material';
import { Box, Tooltip } from '@mui/material';
import { StyledIconButton } from './my_react-material-ui-carousel/Styled';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';

const PhotoInfoBlob = ({imageUrl, fontColor, title, children, sx}) => {
    const outerStyles = {
        width: '100%',
        maxWidth: '400px', // Adjust width as desired
        minHeight: '400px', // Adjust height as needed or use specific value 
        padding: '0px 75px', // Adjust padding from the sides of the image
        // padding: '0% 10%', // Adjust padding from the sides of the image
    };
    
    const innerStyles = {
        // backgroundColor: 'rgba(255, 255, 255, 0.8)', // Background color for the text area
        color: `${fontColor}`,
        textAlign: "center",
        // maxWidth: '400px',
        // We might just need to adjust the width or center in a flexbox
        padding: '0px 65px', // Adjust padding inside the inner div
        // padding: '0% 2%', // Adjust padding from the sides of the image
    };

    return (
        <Box display={'flex'} flexDirection="column"
                             justifyContent="center"
                             alignItems="center"
                             sx={{
                                width: '100%',
                                backgroundImage: `url('${imageUrl}')`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                             }}>
            <Box sx={outerStyles} display={'flex'}>
                <Box sx={innerStyles} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Typography variant='body1' textAlign={'center'} letterSpacing={".2rem"} textTransform={"uppercase"}>
                        {title}
                    </Typography>
                    <Typography variant='body1'>
                        {children}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

const PhotoSlide = ({ photo, isHovered, isPaused, togglePause}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
            }}
        >
            <Box sx={{  width: '100%',
                        textAlign: 'center',
                        minHeight: '400px',
                        // maxHeight:"400px",
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center' }}>
                <img src={photo.url} alt={photo.alt} style={{ maxWidth: '100%', maxHeight: 'inherit' }} />
            </Box>
            {(isPaused||isHovered) && (
            <Box>
                <Tooltip title={isPaused ? '' : ''} arrow>
                    <Box
                    onClick={() => togglePause()}
                    sx={{
                        // position: 'absolute',
                        // top: '50%',
                        // left: '50%',
                        display: 'flex',
                        // transform: 'translate(-50%, -50%)',
                        background: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        borderRadius: '50%',
                        width: '50px',
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        }}
                        >
                    
                    {isPaused ? '▶' : '❚❚'}
                    </Box>
            </Tooltip>
          </Box>
        )}
        {(!isPaused && !isHovered) && (
            <Box>
                    <Box
                    sx={{
                        // position: 'absolute',
                        // top: '50%',
                        // left: '50%',
                        display: 'flex',
                        // transform: 'translate(-50%, -50%)',
                        background: 'white',
                        color: 'white',
                        borderRadius: '50%',
                        width: '50px',
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        }}
                        >
                    </Box>
          </Box>
        )}
            <PhotoInfoBlob fontColor={"#FFFFFF"} imageUrl={photo.bgUrl} title={photo.title}>
                {photo.caption}
            </PhotoInfoBlob>
        </Box>
    );
};


const PhotoCarousel = ({ photos }) => {
    const [isPaused, setIsPaused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const togglePause = (override) => {
        if (override !== undefined) {
            setIsPaused(override);
        } else{
            setIsPaused(!isPaused);
        }
    };
  
  
    return (
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        // onClick={() => togglePause()}
        style={{ position: 'relative', cursor: 'pointer' }}
      >
        <Carousel
                autoplay={true}
                //   ref={carouselRef}
                paused={isPaused}
                swipe={false}
                interval={8000} // Adjust interval as needed
                indicatorContainerProps={{
                    style: {
                    display: 'none',
                    height: '0px',
                    margin: '0px',
                    padding: '0px',
                    },
                    }}
                    navButtonsAlwaysVisible={(isPaused||isHovered)}
                    changeOnFirstRender={false}
                    // navButtonWrapperProps={{
                    //     onClick: () => console.log('Clicked'),
                    // }}
                    NavButton={({onClick, className, style, next, prev}) => {
                        // Other logic
                        const onClickWrapper = () => {
                            togglePause(true);
                            onClick();
                        }
                        return (
                            <StyledIconButton onClick={onClickWrapper} className={className} style={style} $alwaysVisible={isHovered||isPaused}>
                                {next && <NavigateNext />}
                                {prev && <NavigateBefore />}
                            </StyledIconButton>
                        )
                    }}

                    sx={{
                    /* Left button */
                    "& .css-hn784z": {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    },
                    "& .css-hn784z button": {
                        top: 'unset !important',
                    },
                    /* Right button */
                    "& .css-1abc02a": {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    },
                    "& .css-1abc02a button": {
                        top: 'unset !important',
                    },
                    }}     
                //   height={1000} // Kinda hacky but it gits rid of the load in animation
                //   sx={{
                //     minHeight: '1000px',
                //   }}
        >
          {photos.map((photo, index) => (
            <PhotoSlide key={index} photo={photo} isHovered={isHovered} isPaused={isPaused} togglePause={togglePause} />
          ))}
        </Carousel>
        
      </Box>
    );
  };

export default PhotoCarousel;
