import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Room from '@mui/icons-material/Room';

const MapIconButton = ({ mapLink }) => { // Destructure props here
    return (
        <IconButton onClick={() => window.open(mapLink)}>
            <Room sx={{ color: '#111111' }}/>
        </IconButton>
    );
}

const Schedule = () => {
    let quartersLink = "https://maps.app.goo.gl/4YLA4pNAEmoQxncg7";
    let mrcLink = "https://maps.app.goo.gl/cML6VLRPgS6WUJQ57"
    let homeLink = "https://maps.app.goo.gl/R7iqb94ikGKmf43u9";
    return (
        <Container maxWidth="xl">
            <Typography variant='h2' textAlign={'center'} letterSpacing={".2rem"} textTransform={"uppercase"} pb={2}>Schedule</Typography>
            
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Box display="flex" justifyContent="space-around" alignItems="center" height="100%" sx={{textAlign:"center"}}>
                        <Box>
                            <Typography variant='h3'>Friday, August 2<sup>nd</sup>, 2024</Typography>
                            <Typography variant='h4'>8:00 - 10:00 PM</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img src="/arcade_machine.png" alt="Attractions" style={{ maxHeight:"300px", width: '100%', objectFit: 'contain' }} />
                </Grid>
                <Grid item xs={12} md={4} pb={2}>
                    <Box display="flex" justifyContent="space-around" alignItems="center" height="100%" sx={{textAlign:"center"}}>
                        <Box>
                            <Typography variant='h3'> Welcome Event </Typography>
                            <Typography variant='h4'>Quarters <MapIconButton mapLink={quartersLink} /> </Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={4} >
                    <Box display="flex" justifyContent="space-around" alignItems="center" height="100%" sx={{textAlign:"center"}}>
                        <Box>
                            <Typography variant='h3'>Saturday,<br/> August 3<sup>rd</sup>, 2024</Typography>
                            <Typography variant='h4'>4:30 - 10:00 PM</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img src="/archway.png" alt="Attractions" style={{ maxHeight:"300px", width: '100%', objectFit: 'contain' }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box display="flex" justifyContent="space-around" alignItems="center" height="100%" sx={{textAlign:"center"}}>
                        <Box>
                            <Typography variant='h3'> Wedding Ceremony<br/> & Reception</Typography>
                            <Typography variant='h4'>Montague Retreat Center <MapIconButton mapLink={mrcLink} /></Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                    <img src="/cocktail.png" alt="Attractions" style={{ height:"300px", width: '100%', objectFit: 'contain' }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <img src="/table_setting.png" alt="Attractions" style={{ height:"300px", width: '100%', objectFit: 'contain' }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <img src="/dancers.jpeg" alt="Attractions" style={{ height:"300px", width: '100%', objectFit: 'contain' }} />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Box display="flex" justifyContent="space-around" alignItems="center" height="100%" sx={{textAlign:"center"}}>
                        <Box>
                            <Typography variant='h3'>Sunday,<br/> August 4<sup>th</sup>, 2024</Typography>
                            <Typography variant='h4'>10:00 AM - NOON</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img src="/house_fixed.png" alt="Attractions" style={{ maxHeight:"300px", width: '100%', objectFit: 'contain' }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box display="flex" justifyContent="space-around" alignItems="center" height="100%" sx={{textAlign:"center"}}>
                        <Box>
                            <Typography variant='h3'>Farewell Brunch</Typography>
                            <Typography variant='h4'>Our Home <MapIconButton mapLink={homeLink} /></Typography>
                        </Box>
                    </Box>
                </Grid>
              
            </Grid>
        </Container>
    );
};

export default Schedule;
