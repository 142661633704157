import React from 'react';
import PhotoCarousel from '../components/PhotoCarousel';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

const Story = () => {
    const theme = useTheme();
    const photos = [
        {
          url: '/slides/year_1_2_crop.jpg',
          bgUrl: 'blob_1_big.png',
          alt: 'Photo 1',
          title: "Year 1",
          caption: 'Hunter and Maddy met at a party at USC the first weekend of senior year. They fell in love over many Saturday Game Days, late nights at college bars in LA, and languid hours spent in relaxed, aimless conversation.',
        },
        {
          url: '/slides/year_2_1_crop.jpg',
          bgUrl: 'blob_2_big.png',
          alt: 'Photo 2',
          title: "Year 2",
          caption: 'With their fresh college degrees in hand, the couple moved to San Francisco to start their careers. They were lucky to have a close-knit friend group in the Bay area to explore the city with.',
        },
        {
            url: '/slides/year_2_4_crop.jpg',
            bgUrl: 'blob_3_big.png',
            alt: 'Photo 3',
            title: "Year 3",
            caption: 'The year California got to us. Hunter’s hair was longer than Maddy’s, the couple went on their first camping trip, and early 20s career angst set in.',
        },
        {
            url: '/slides/year_4_5_crop.jpg',
            bgUrl: 'blob_4_big.png',
            alt: 'Photo 4',
            title: "Year 4",
            caption: 'Maddy and Hunter quit their jobs in San Francisco, traveled around the world, and moved across the country. Maddy moved to New Haven for grad school, and Hunter followed to New York.',
        },
        {
            url: '/slides/year_4_7_crop.jpg',
            bgUrl: 'blob_5_big.png',
            alt: 'Photo 5',
            title: "Year 5",
            caption: 'Just as they were settling in, Covid-19 disrupted in-person learning and life in New York. Hunter moved in with Maddy and her roommate Annie in New Haven. They learned they could lean on one another during tough times.',
        },
        {
            url: '/slides/year_6_5_crop.jpg',
            bgUrl: 'blob_6_big.png',
            alt: 'Photo 6',
            title: "Year 6",
            caption: 'Maddy and Hunter moved into their own apartment in New Haven and adopted their precious angel kitties Sokka and Katara. Hunter discovered his love and aptitude for teaching, while Maddy balanced classes, clinical rotations, and working as a nurse.',
        },
        {
            url: '/slides/year_7_4_crop.jpg',
            bgUrl: 'blob_7_big.png',
            alt: 'Photo 7',
            title: "Year 7",
            caption: 'Was a big year! Maddy graduated nursing school, Hunter got into grad school for his PhD, Hunter proposed to Maddy, and the couple bought their first home in Amherst, MA.',
        },
        {
            url: '/slides/year_8_4_crop.jpg',
            bgUrl: 'blob_8_big.png',
            alt: 'Photo 8',
            title: "Year 8",
            caption: 'Maddy settled into her new job as a Family Nurse Practitioner and Hunter started learning how large language models work. And they’re getting married! ',
        }
        // Add more photo objects as needed
      ];
    return (
        <div>
            {/* <Typography variant='h2' textAlign={'center'} letterSpacing={".2rem"} textTransform={"uppercase"}>Story</Typography> */}
            <Box sx={{width:"100%"}}>
                <Box sx={{ display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Box 
                        sx={{ [theme.breakpoints.down('sm')]: {
                                        width: '400px',
                                    },
                                    [theme.breakpoints.up('sm')]: {
                                        width: '750px',
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        width: '750px',
                                    },
                                    [theme.breakpoints.up('xl')]: {
                                        width: '750px',
                                    },
                                }}
                                >
                        <PhotoCarousel photos={photos} />
                        </Box>
                </Box>
            </Box>
        </div>
    );
};

export default Story;