import React from 'react'
import { Grid, Box } from '@mui/material'
import { useMediaQuery, useTheme } from '@mui/material'


const SplitSection = (props) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const textSection = <Grid item xs={12} md={6} style={{backgroundColor: props.bgColor}}>
                            <Box p={isSmallScreen? 0 : 2} sx={{display:"flex",
                                            textAlign:"left",
                                            flexDirection:"column",
                                            alignItem:"left",
                                            justifyContent:"center"}}
                                        >{props.children}</Box>
                        </Grid>
                        
    const imageSection = <Grid item xs={12} md={6} style={{backgroundImage: 'url('+props.imgPath+')',
                                                    backgroundSize:"contain",
                                                    // maxWidth:"400px",
                                                    minHeight:"250px",
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat:"no-repeat",
                                                    backgroundPosition:'center'}}
                                                    ></Grid>
    if(props.reverse) {
        return(
        
            <Box>
                <Grid container style={{overflow:"clip"}} pb={3}>
                    {imageSection}
                    {textSection}
                </Grid>
            </Box>
        )   
    } else {
        return (
            <Box>
                <Grid container style={{overflow:"clip"}} pb={3}>
                    {textSection}
                    {imageSection}
                </Grid>
            </Box>
        )
    }
}
export default SplitSection