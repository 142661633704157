import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Link, IconButton, Box, Drawer, List, ListItem, ListItemText, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';

const Navbar = () => {
    const [specialPasswordEntered, setSpecialPasswordEntered] = useState(false); // Set default password state to false
    const [menuOpen, setMenuOpen] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const handleDrawerOpen = () => {
        setMenuOpen(true);
    };

    const toggleDrawer = (currentState) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setMenuOpen(!currentState);
    };

    useEffect(() => {
        const specialPasswordEntered = localStorage.getItem('specialPasswordEntered');
        setSpecialPasswordEntered(!!specialPasswordEntered); // Convert to boolean
    }, []);
    

    // Central object for navigation links
    let links = [
        { text: "Story", href: "story" },
        { text: "Schedule", href: "schedule" },
        { text: "Sights", href: "sights" },
        { text: "Stay", href: "stay" },
        { text: "Registry", href: "https://www.zola.com/registry/hunterandmadelyn2024", target: "_blank", rel: "noopener noreferrer" },
        { text: "FAQ", href: "faq" }
    ];

    if (specialPasswordEntered) {
        links.unshift({ text: "Munchkins", href: "munchkins"});
    }

    return (
        <AppBar position="static" color="default" elevation={0} sx={{ backgroundColor: "white" }}>
            <Toolbar sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {/* Maddy & Hunter Logo */}
                <Typography variant="h6" color="inherit" noWrap>
                    <Link href="/" style={{ textDecoration: "none", color: "black", display: "flex", alignItems: "center" }}>
                        <img src="cats_cropped_small.png" alt="Wedding Logo" height="80px" style={{ marginRight: "8px", margin: "4px" }} />
                        Maddy & Hunter
                    </Link>
                </Typography>

                {/* Menu Icon for smaller screens */}
                {isMobile && (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                )}

                {/* Drawer for navigation links */}
                <Drawer
                    anchor='top'
                    open={menuOpen}
                    onClose={toggleDrawer(menuOpen)}
                >
                    <Box m={5}>
                        <List>
                            {/* Render navigation links dynamically */}
                            {links.map((link, index) => (
                                link.text === "Munchkins" ? (
                                    <ListItem button component="a" href={link.href} key={index} style={{ padding: [0, 32], textAlign:'right', color:"#BF8234" }} target={link.target} rel={link.rel}>
                                        <ListItemText primary={link.text} />
                                    </ListItem>
                                ) : (
                                    <ListItem button component="a" href={link.href} key={index} style={{ padding: [0, 32], textAlign:'right' }} target={link.target} rel={link.rel}>
                                        <ListItemText primary={link.text} />
                                    </ListItem>
                                )
                            ))}
                            <ListItem component="a" href='https://forms.gle/MNTbuGys3rguJv5r7' style={{textAlign:"right"}} target='_blank' rel="noopener noreferrer">
                                <ListItemText primary=""/>
                                <Button color="olive" variant="contained" href="https://forms.gle/MNTbuGys3rguJv5r7" target='_blank' rel="noopener noreferrer">
                                    <Typography variant='body'>RSVP</Typography>
                                </Button>
                            </ListItem>
                        </List>
                    </Box>
                </Drawer>

                {/* Navigation links for larger screens */}
                {!isMobile && (
                    <nav>
                        {/* Render navigation links dynamically */}
                        {links.map((link, index) => (
                            link.text === "Munchkins" ? (
                                <Link variant="button" color="#BF8234" href={link.href} sx={{ my: 1, mx: 1.5 }} key={index} target={link.target} rel={link.rel}>
                                    {link.text}
                                </Link>
                            ) : (
                                <Link variant="button" color="text.primary" href={link.href} sx={{ my: 1, mx: 1.5 }} key={index} target={link.target} rel={link.rel}>
                                    {link.text}
                                </Link>
                            )
                        ))}
                            <Button color="olive" variant="contained" href="https://forms.gle/MNTbuGys3rguJv5r7" target='_blank' rel="noopener noreferrer">
                                <Typography variant='body'>RSVP</Typography>
                            </Button>
                    </nav>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
