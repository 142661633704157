import React from 'react';
import { Container, Typography, Grid } from '@mui/material';
import Box from '@mui/material/Box';

const FAQ = () => {
    return (
        <Container maxWidth="xl">
            <Typography variant='h3' textAlign={'center'} letterSpacing={".2rem"} textTransform={"uppercase"} pb={2}>Frequently Asked Questions</Typography>
            <Box display="flex" justifyContent="center">
                <Grid container maxWidth={'md'} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Can I bring my kids?</Typography>
                        <Typography variant='h6'>Due to venue constraints our celebration is for adults 18+ only. While we would love to celebrate with all your children, we’re only able to accommodate infants and toddlers of the bridal party. </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h4'>What health and safety measures will be in place?</Typography>
                        <Typography variant='h6'>We will not be requesting our guests covid test prior to the wedding, but we ask that you follow general precautions (i.e. take a covid test if you’re feeling sick, stay home if it’s positive).</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h4'>How do I get there?</Typography>
                        <Typography variant='h6'>If you're coming from NY you can take the Amtrak train into Northampton. Travel time is about 4.5 hours. If you're flying in from another state, the closest airport is BDL in Hartford, CT. The drive from BDL to downtown Northampton takes about 45 minutes. You can rent a car, call an uber/lyft, or book a shuttle through Valley Transporter. You can also fly into BOS in Boston, MA. The drive to Northampton from BOS is about 2 hours. </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h4'>What should I wear to the wedding?</Typography>
                        <Typography variant='h6'>Cocktail attire. The ceremony will be outdoors (weather permitting) without cover and will require walking up a small hill with grass and gravel, so sensible shoes and sunglasses would be a good idea.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Is the venue handicap accessible?</Typography>
                        <Typography variant='h6'>Guests can be dropped off at the top of the hill for the wedding ceremony and driven down to the area for cocktail hour afterwards.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h4'>How should I get around?</Typography>
                        <Typography variant='h6'>Transportation will be provided from downtown Northampton to the wedding venue and back on Saturday. Ride sharing services like Uber and Lyft are not very reliable in the Pioneer Valley, so you will likely want to rent a car or coordinate rides with others. There is also ample parking at the wedding venue and you can leave your car onsite overnight (until 10am Sunday) if needed.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Where will wedding updates be posted?</Typography>
                        <Typography variant='h6'>We’ll send out an email to let you know if there are major updates.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Why did it take so long for you to publish your website?</Typography>
                        <Typography variant='h6'>It's all Hunter's fault.</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default FAQ;
