import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#808080', // Set primary color to grey
    },
    text: {
      primary: '#000000', // Set text color to black
    },
  },
});

const Overlay = ({ onSubmit }) => {
  const [password, setPassword] = useState('');

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(password);
  };

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      backgroundColor="#6A6432" // Semi-transparent black overlay
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex={9999}
    >
      <Box
        backgroundColor="#fff"
        padding={3}
        borderRadius={5}
        maxWidth={400}
        width="80%"
        textAlign="center"
      >
        <Typography variant="h5" gutterBottom>
          Enter Password
        </Typography>
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button variant="outlined" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Overlay;
