import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Container, Typography, Button } from '@mui/material';

const Home = () => {
  const theme = useTheme();
  const isMedScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ backgroundColor: "white" }}>
      <Container maxWidth="xl" style={{ backgroundColor: "#FFFFFF" }}>
        {/* Use the Typography components with variant='h1' and variant='h3' */}
        <Box sx={{
          width: "100%",
          minHeight: "400px",
          backgroundImage: "url('/cats_tweaked-transformed.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          [theme.breakpoints.down('sm')]: {
            minHeight: "240px",
          },
        }}
        ></Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} mt={2}>
          {/* Apply a custom class conditionally based on screen size */}
          <Typography variant='h1' sx={{ textAlign: "center", marginTop: "1rem", fontSize: isSmallScreen ? '3rem' : '6rem' }} fontFamily="Sacramento" >
            Madelyn McCadden
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', marginTop: '1rem' }}>
            {/* <hr style={{ width: '250px', height: '1px', backgroundColor: '#333333', border:'none', margin: '0 10px' }} /> */}
            <Typography variant='h1' fontFamily="Sacramento" sx={{ textAlign: "center", fontSize: isSmallScreen ? '3rem' : '6rem' }} >&</Typography>
            {/* <hr style={{ width: '250px', height: '1px', backgroundColor: '#333333', border:'none', margin: '0 10px' }} /> */}
          </div>
          <Typography variant='h1' sx={{ textAlign: "center", marginTop: "1rem", fontSize: isSmallScreen ? '3rem' : '6rem' }} fontFamily="Sacramento" >
            Hunter McNichols
          </Typography>
          <Typography my={2} variant='h3' fontFamily="Poiret+One" letterSpacing=".2rem" textAlign={'center'} sx={{ textAlign: "center", marginTop: "1rem", fontSize: isSmallScreen ? '2rem' : '4rem' }}>
            Saturday
            {isMedScreen ? <br /> : ', '}August 3<sup>rd</sup>, 2024
          </Typography>
          {/* <hr style={{ width: '250px', height: '1px', backgroundColor: '#333333', border:'none', margin: '0 10px' }} /> */}
          <Box p={3}>
            <Button color="olive" variant="contained" href="https://forms.gle/MNTbuGys3rguJv5r7" target='_blank' rel="noopener noreferrer">
              <Typography variant='h3' letterSpacing={6}>RSVP</Typography>
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Home;
